var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "background-color": "#F2F2F2"
    }
  }, [_c('back-off', {
    attrs: {
      "url": _vm.backUrl,
      "title": "Address List"
    }
  }), _vm._l(_vm.addressList, function (item, index) {
    return _c('div', {
      key: index,
      staticStyle: {
        "position": "relative",
        "width": "94.87vw",
        "height": "32.53vw",
        "margin-left": "2.565vw",
        "margin-top": "2vw",
        "border-radius": "1.56vw",
        "background-color": "#FFFFFF"
      }
    }, [item.isDefault == 1 ? _c('span', {
      staticStyle: {
        "position": "absolute",
        "top": "2vw",
        "right": "3vw",
        "width": "12.82vw",
        "height": "5.41vw",
        "background-color": "#D9D9D9",
        "font-size": "3.59vw",
        "border": "solid 0.3vw #BBBBBB",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "border-radius": "1vw"
      }
    }, [_vm._v("default")]) : _vm._e(), _c('div', {
      staticStyle: {
        "width": "90.87vw",
        "height": "8.68vw",
        "margin-left": "4vw",
        "margin-top": "2vw",
        "align-items": "center",
        "display": "flex"
      }
    }, [_vm._v(" " + _vm._s(item.consignee) + "   " + _vm._s(item.phone) + " ")]), _c('div', {
      staticStyle: {
        "width": "90.87vw",
        "height": "5.68vw",
        "margin-left": "4vw",
        "align-items": "center",
        "display": "flex"
      }
    }, [_vm._v(" " + _vm._s(item.addressLine2) + "   " + _vm._s(item.addressLine1) + " ")]), _c('div', {
      staticStyle: {
        "width": "90.87vw",
        "height": "8.68vw",
        "margin-left": "4vw",
        "margin-bottom": "0vw",
        "align-items": "center",
        "display": "flex"
      }
    }, [_vm._v(" " + _vm._s(item.city) + "   " + _vm._s(item.state) + "   " + _vm._s(item.postalCode) + " ")]), _c('div', {
      staticStyle: {
        "margin-bottom": "1vw",
        "width": "90.87vw",
        "height": "8.68vw",
        "margin-left": "4vw",
        "margin-top": "0vw",
        "align-items": "center",
        "justify-content": "flex-end",
        "display": "flex"
      }
    }, [item.isDefault != 1 && !_vm.selectAddress ? _c('van-button', {
      staticStyle: {
        "font-size": "3.59vw",
        "width": "28.31vw",
        "height": "6.97vw",
        "border-radius": "1.56vw",
        "border": "solid 0.3vw #BBBBBB"
      },
      on: {
        "click": function ($event) {
          return _vm.setDefault(item.id);
        }
      }
    }, [_vm._v("Set Default")]) : _vm._e(), !_vm.selectAddress ? _c('van-button', {
      staticStyle: {
        "margin-left": "2vw",
        "font-size": "3.59vw",
        "width": "22.31vw",
        "height": "6.97vw",
        "border-radius": "1.56vw",
        "border": "solid 0.3vw #BBBBBB"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteAddress(item.id);
        }
      }
    }, [_vm._v("Delete")]) : _vm._e(), !_vm.selectAddress ? _c('van-button', {
      staticStyle: {
        "margin-left": "2vw",
        "margin-right": "2vw",
        "font-size": "3.59vw",
        "width": "22.31vw",
        "height": "6.97vw",
        "border-radius": "1.56vw",
        "border": "solid 0.3vw #BBBBBB"
      },
      on: {
        "click": function ($event) {
          return _vm.onEdit(item);
        }
      }
    }, [_vm._v("Edit")]) : _vm._e(), _vm.selectAddress ? _c('van-button', {
      staticStyle: {
        "margin-left": "2vw",
        "margin-right": "2vw",
        "font-size": "3.59vw",
        "width": "22.31vw",
        "height": "8.97vw",
        "border-radius": "1.56vw",
        "border": "solid 0.3vw #BBBBBB"
      },
      on: {
        "click": function ($event) {
          return _vm.onSelect(item);
        }
      }
    }, [_vm._v("Use")]) : _vm._e()], 1)]);
  }), _vm.addAddressFlag ? _c('add-address-popup', {
    attrs: {
      "add-address-popup-show": _vm.addAddressFlag
    },
    on: {
      "callbackForAdd": _vm.callbackForAdd
    }
  }) : _vm._e(), _vm.editAddressFlag ? _c('edit-address-popup', {
    attrs: {
      "old-address": _vm.oldAddress,
      "edit-address-popup-show": _vm.editAddressFlag
    },
    on: {
      "callbackForEdit": _vm.callbackForEdit,
      "callbackForCancelEdit": _vm.callbackForCancelEdit
    }
  }) : _vm._e(), _c('van-button', {
    staticStyle: {
      "height": "14vw",
      "position": "fixed",
      "bottom": "0px",
      "font-size": "5vw",
      "background-color": "#D56460",
      "border": "solid 0vw #D56460"
    },
    attrs: {
      "type": "primary",
      "block": ""
    },
    on: {
      "click": _vm.onAdd
    }
  }, [_vm._v("Add Address")])], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };