import "core-js/modules/es.array.push.js";
import { Button } from 'vant';
import BackOff from '../common/BackOff.vue';
import AddAddressPopup from '../common/AddAddressPopup.vue';
import EditAddressPopup from '../common/EditAddressPopup.vue';
import { addressList, addressSetDefault, addressDel } from '../../api/user';
export default {
  components: {
    BackOff,
    AddAddressPopup,
    EditAddressPopup,
    [Button.name]: Button
  },
  data() {
    return {
      index: Number,
      addAddressFlag: false,
      // 修改地址弹窗是否展示
      editAddressFlag: false,
      addressList: [],
      userInfo: {},
      backUrl: this.$route.query.backUrl,
      selectAddress: this.$route.query.address,
      chosenAddressId: 0,
      oldAddress: {},
      disabledList: [],
      isShow: false
    };
  },
  created: async function () {
    this.getAddressList();
  },
  methods: {
    deleteAddress(id) {
      const addressId = {
        addressId: id
      };
      addressDel(addressId).then(res => {
        res;
        this.getAddressList();
      });
    },
    setDefault(id) {
      const addressId = {
        addressId: id
      };
      addressSetDefault(addressId).then(res => {
        res;
        this.getAddressList();
      });
    },
    async getAddressList() {
      const result = await addressList();
      this.addressList = result.data.data.addresses;
    },
    // onClickLeft() {
    //   // Toast('返回')
    // },
    // 修改地址
    onEdit(item) {
      this.oldAddress = item;
      this.editAddressFlag = false;
      this.editAddressFlag = true;
      this.addAddressFlag = false;
    },
    // 确定修改地址回调
    callbackForEdit() {
      this.editAddressFlag = false;
      this.getAddressList();
    },
    // 取消修改地址回调
    callbackForCancelEdit() {
      this.editAddressFlag = false;
    },
    onAdd() {
      this.addAddressFlag = false;
      this.addAddressFlag = true;
      this.editAddressFlag = false;
    },
    callbackForAdd() {
      this.addAddressFlag = false;
      this.getAddressList();
    },
    onSelect(item) {
      localStorage.setItem('selectedAddressId', item.id);
      this.$router.push({
        path: this.backUrl
      });
    }
  }
};